<template>
  <div class="impressum">

    <div class="impressum__title" v-html="$t('impressum/title')"></div>

    <div
      v-for="(item, i) in text"
      :key="i"
      v-html="item"
      class="impressum__text"
    ></div>

  </div>
</template>


<script lang="js">
export default {
  name: "Impressum",
  computed: {
    text() {
      return this.$t('impressum/text');
    },
  },
  mounted() {    
    this.$store.state.buttonHome = true;
    this.$store.state.buttonBack = false;
    this.$store.state.buttonCart = true;
    this.$store.state.showMenu = true;

    window.scrollTo(0, 0);
  },
};
</script>


<style lang="scss" scoped>
  @import "./impressum";
</style>
